body {
  color: #000;
}

a, a:visited, a:hover {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

.navbar {
  vertical-align: center;
  margin-bottom: 20px;
  line-height: 1rem;
}

.navbar a {
  color: #000;
  margin: 5px 0;
}

.navbar .btn {
  color: #000;
  text-decoration: none;
}

.navbar .btn:hover {
  color: #000;
  text-decoration: underline;
}

.navbar h3, .navbar h1 {
  margin: 0;
  padding: 0;
}

.navbar img {
  width: 25px;
  height: 25px;
  vertical-align: center;
}

.divider[data-content] {
  margin: 2rem 0;
}

div.small-genesis-image, div.tiny-genesis-image {
  text-align: center;
  color: #000;
  padding: 15px;
}

div.token-label {
  color: #8d8d8d;
  max-width: 256px;
}

#section-physical-genesis-gallery img {
  -webkit-filter: drop-shadow(5px 5px 10px #32325d40);
}

img.token {
  box-shadow: 0 6px 12px -2px #32325d40, 0 3px 7px -3px #0000004d;
}

img.token-transparent {
  -webkit-filter: drop-shadow(5px 5px 10px #32325d40);
}

#page-individual-genesis .token-image {
  color: #fff;
  padding: 10px 20px;
}

#page-individual-genesis .all-token-images {
  text-align: center;
  margin: 10px;
}

#page-individual-genesis .all-token-images .gallery {
  color: #fff;
  background-color: #999;
  border-radius: 10px;
  font-size: .7em;
}

#page-individual-genesis .all-token-images .gallery .col-3 {
  padding: 15px;
}

#page-individual-genesis iframe {
  width: 100%;
  height: 100px;
  border: 1px solid #d8d8d8;
  margin: 0;
  padding: 0;
}

.owner-wallet, .logo-links {
  text-align: center;
}

.logo-links img {
  height: 20px;
  width: 20px;
  margin: 5px 10px;
}

.token-header {
  text-align: center;
}

.scroll-links {
  padding: 5px 15px;
}

.scroll-links a {
  color: #000;
  box-shadow: 0 2px 8px #63636333;
}

#candy-gram-mint > div {
  padding: 10px;
}

#candy-gram-mint .instructions {
  font-size: .6rem;
  line-height: .6rem;
}

.tweet-preview {
  height: 300px;
  text-align: center;
  vertical-align: center;
  color: #ddd;
  border: 1px solid #ddd;
  line-height: 300px;
}

#pageCandygramMint h5 {
  text-align: center;
  padding: 10px;
}

kbd.blink {
  animation: 2s linear infinite blink;
}

@keyframes blink {
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
}

#pageCandygramMint .hide {
  display: none;
}

#pageCandygramMint .show {
  display: block;
}

#pageCandygramMint .Step0Submit {
  text-align: center;
}

#mintCodeForm div, #tweetIdForm div {
  margin-bottom: 20px;
  padding: 10px;
}

.loading {
  height: 300px;
}

.bcbcbh {
  height: 300px;
  text-align: center;
  background-color: #f7f8f9;
}

.bcbcbh .s-circle {
  height: 280px;
  width: 280px;
  vertical-align: middle;
  text-align: center;
  border: 3px solid #000;
  margin: 10px auto;
  padding-top: 90px;
  font-size: 25px;
  line-height: 30px;
}

#dotmaps-banner {
  margin-top: 20px;
  margin-bottom: 30px;
}

#dig-phy-btns {
  margin-bottom: ;
}

#pixel-sizes {
  max-width: 250px;
  float: right;
  margin-left: 15px;
}

#tiny-avatars .column {
  padding-bottom: .5rem;
}

/*# sourceMappingURL=index.c6c52b9f.css.map */
