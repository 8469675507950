body{
  color: black;
}
a, a:visited, a:hover{
  color:black;
  text-decoration: underline;
  cursor: pointer;
}
a:hover{

}
.navbar {
  margin-bottom:20px;
  vertical-align:center;
  line-height: 1rem;
}
.navbar a{
  margin: 5px 0px;
  color:black;
}
.navbar .btn {
  color:black;
  text-decoration: none;
}
.navbar .btn:hover {
  color: black;
  text-decoration: underline;
}
.navbar h3,.navbar h1{
  margin:0;
  padding:0;
}
.navbar img{
  width:25px;
  height:25px;
  vertical-align:center;
}

.divider[data-content]{
  margin:2rem 0;
}

div.small-genesis-image, div.tiny-genesis-image {
  padding:15px;
  text-align: center;
  color: black;
}
div.token-label {
  color:#8d8d8d;
  max-width: 256px;
}
#section-physical-genesis-gallery img{
  -webkit-filter: drop-shadow(5px 5px 10px rgba(50, 50, 93, 0.25));
}


img.token{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
img.token-transparent{
  -webkit-filter: drop-shadow(5px 5px 10px rgba(50, 50, 93, 0.25));
}

#page-individual-genesis .token-image{
  padding: 10px 20px;
  color:#ffffff;
}

#page-individual-genesis .all-token-images {
  margin:10px;
  text-align: center;
}

#page-individual-genesis .all-token-images>div {

}
#page-individual-genesis .all-token-images .gallery{
  background-color:#999999;
  border-radius: 10px;
  color:#ffffff;
  font-size: 0.7em;
}
#page-individual-genesis .all-token-images .gallery .col-3{
  padding:15px;
}
#page-individual-genesis .all-token-images .filetype {

}
#page-individual-genesis iframe{
  margin: 0;
  padding: 0;
  border: 1px solid #d8d8d8;
  width: 100%;
  height: 100px;
}

.owner-wallet {
  text-align:center;
}
.logo-links {
  text-align:center;
}
.logo-links img{
  height: 20px;
  width: 20px;
  margin: 5px 10px;
}

.token-header{
  text-align:center;
}
.scroll-links {
  padding: 5px 15px;
}
.scroll-links a{
  color:black;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#candy-gram-mint>div{
  padding: 10px;
}
#candy-gram-mint .instructions{
  font-size: 0.6rem;
  line-height: 0.6rem;
}
.tweet-preview{
  height:300px;
  line-height: 300px;
  text-align:center;
  vertical-align:center;
  color:#ddd;
  border: 1px solid #ddd;
}
#pageCandygramMint h5{
  text-align:center;
  padding:10px;
}


kbd.blink{
  animation: blink 2s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

#pageCandygramMint .hide{
  display: none;
}

#pageCandygramMint .show{
  display: block;
}

#pageCandygramMint .Step0Submit{
  text-align:center;
}

#mintCodeForm div,
#tweetIdForm div{
  padding:10px;
  margin-bottom:20px;
}




.loading{
  height:300px;
}

.bcbcbh{
  height:300px;
  background-color:#f7f8f9;
  text-align:center;
}
.bcbcbh .s-circle{
  margin: 10px auto;
  height: 280px;
  width: 280px;
  font-size: 25px;
  line-height: 30px;
  padding-top:90px;
  border: 3px solid black;
  vertical-align:middle;
  text-align:center;
}




#dotmaps-banner{
  margin-top:20px;
  margin-bottom: 30px;
}
#dig-phy-btns{
  margin-bottom:
}
#pixel-sizes{
  max-width:250px;
  float:right;
  margin-left:15px;
}
#tiny-avatars .column {
  padding-bottom:0.5rem;
}
